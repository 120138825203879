/* eslint-disable no-secrets/no-secrets */
import gql from 'graphql-tag';

import { CAROUSEL_CONTENT_FRAGMENT } from './carousel-content';

export const EVENT_CAROUSEL_FRAGMENT = gql`
  ${CAROUSEL_CONTENT_FRAGMENT}
  fragment EventCarouselBlockFields on EventCarouselBlock {
    sys {
      id
    }
    title
    eventTag
    sortingOrder
    platforms
    genresCollection {
      items {
        title
        displayTitle
      }
    }
    contentCollection {
      items {
        ...CarouselContentEventFields
        ...CarouselContentTileFields
      }
    }
    landscape
    displayDate
  }
`;

export const EVENT_FILTERED_CAROUSEL_FRAGMENT = gql`
  fragment EventFilteredCarouselBlockFields on EventCarousel {
    sys {
      id
    }
    title
    imageOrientation
    showDates
  }
`;

export const EVENT_EDITORIAL_CAROUSEL_FRAGMENT = gql`
  fragment EventEditorialCarouselBlockFields on EditorialCarousel {
    sys {
      id
    }
    title
    imageOrientation
    showDates
  }
`;
export const GENERIC_CAROUSEL_FRAGMENT = gql`
  fragment GenericCarouselBlockFields on CarouselGeneric {
    sys {
      id
    }
    title
  }
`;
